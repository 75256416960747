<template>
  <div
    v-if="!isLoadingMess"
    class="custom-scroll h-[72%] overflow-auto hidden-scroll py-2 mb-[20px]"
  >
    <div
      v-if="listHistoryMess && listHistoryMess.messages"
      v-for="(mess, indexMess) in reversedMessages"
      :key="indexMess"
      class="relative"
    >
      <div
        v-if="listTime.length > 0 && listTime[indexMess].active"
        class="w-full justify-center flex"
      >
        <p class="text-ems-gray400">
          {{ convertUnixToDate(mess.timeUnix, locale) }}
        </p>
      </div>
      <div
        v-if="mess.senderId === idUser"
        class="w-full flex items-center justify-end space-x-2 cursor-pointer mt-1 min-h-[50px] pr-2"
        @mouseenter="handleMouseEnter(event, indexMess)"
        @mouseleave="handleMouseLeave(event, indexMess)"
      >
        <div v-if="isHovered === indexMess" class="flex space-x-[9px]">
          <div
            class="h-[38px] w-[38px] rounded-full bg-ems-gray600 flex justify-center items-center"
            @click="handleDeleteMess(mess)"
          >
            <img alt="delete" src="@/static/img/icon/delete.svg" />
          </div>
          <div
            class="h-[38px] w-[38px] rounded-full bg-ems-gray600 flex justify-center items-center"
            @click="handleSendMess(mess)"
          >
            <img alt="share" src="@/static/img/icon/share.svg" />
          </div>
        </div>
        <div class="flex items-center">
          <TextMess
            v-if="mess.messageType === 'text'"
            :forwarded="mess.forwarded"
            :forwardFrom="mess.forwardFrom"
            :content="mess.content"
            :isMyAccount="true"
          />
          <div
            v-else-if="mess.messageType === 'file'"
            class="max-w-[274px] h-max rounded-[20px]"
          >
            <ImageMess
              v-if="mess.mediaType === 'image'"
              :forwarded="mess.forwarded"
              :forwardFrom="mess.forwardFrom"
              :url="mess.url"
              :isMyAccount="true"
            />
            <VideoMess
              v-else-if="mess.mediaType === 'video'"
              :forwarded="mess.forwarded"
              :forwardFrom="mess.forwardFrom"
              :url="mess.url"
              :isMyAccount="true"
            />
            <AudioMess
              v-else-if="mess.mediaType.includes('audio')"
              :mess="mess"
              @handlePause="handlePause(mess)"
              @handlePlay="handlePlay(mess)"
              :isPlayingId="isPlayingId"
              :forwarded="mess.forwarded"
              :forwardFrom="mess.forwardFrom"
              :audioPlayer="audioPlayer"
              :isMyAccount="true"
            />
          </div>
        </div>
      </div>
      <div
        v-else
        :class="`w-full flex items-center justify-start space-x-2 mt-1 cursor-pointer pl-2 relative ${
          itemValue.otherInfo.peerType === 1
            ? 'min-h-[50px] py-0'
            : 'py-3 min-h-[75px]'
        }`"
        @mouseenter="handleMouseEnter(event, indexMess)"
        @mouseleave="handleMouseLeave(event, indexMess)"
      >
        <div
          v-if="itemValue.otherInfo.peerType === 2"
          class="h-[30px] w-[30px] rounded-full flex justify-center items-center overflow-hidden border flex-shrink-0"
        >
          <img
            v-if="listHistoryMess.AttachedUser"
            alt="avatar"
            :src="getAvatarAttached(mess)"
            class="h-auto w-full"
          />
        </div>
        <p
          v-if="itemValue.otherInfo.peerType === 2"
          class="absolute top-[-5px] left-[40px] text-white"
        >
          {{ getFullNameAttached(mess) }}
        </p>
        <TextMess
          v-if="mess.messageType === 'text'"
          :forwarded="mess.forwarded"
          :forwardFrom="mess.forwardFrom"
          :content="mess.content"
          :isMyAccount="false"
        />
        <div
          v-if="mess.messageType === 'file'"
          class="max-w-[274px] h-max rounded-[20px]"
        >
          <ImageMess
            v-if="mess.mediaType === 'image'"
            :forwarded="mess.forwarded"
            :forwardFrom="mess.forwardFrom"
            :url="mess.url"
            :isMyAccount="false"
          />
          <VideoMess
            v-else-if="mess.mediaType === 'video'"
            :forwarded="mess.forwarded"
            :forwardFrom="mess.forwardFrom"
            :url="mess.url"
            :isMyAccount="false"
          />
          <AudioMess
            v-else-if="mess.mediaType.includes('audio')"
            :mess="mess"
            @handlePause="handlePause(mess)"
            @handlePlay="handlePlay(mess)"
            :isPlayingId="isPlayingId"
            :forwarded="mess.forwarded"
            :forwardFrom="mess.forwardFrom"
            :audioPlayer="audioPlayer"
            :isMyAccount="false"
          />
        </div>
        <div v-if="isHovered === indexMess" class="flex space-x-[9px]">
          <div
            class="h-[38px] w-[38px] rounded-full bg-ems-gray600 flex justify-center items-center"
            @click="handleDeleteMess(mess)"
          >
            <img alt="delete" src="@/static/img/icon/delete.svg" />
          </div>
          <div
            class="h-[38px] w-[38px] rounded-full bg-ems-gray600 flex justify-center items-center"
            @click="handleSendMess(mess)"
          >
            <img alt="share" src="@/static/img/icon/share.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <ModalDelete
    :isPopupDelete="isShowDelete.active"
    @handleConfirm="handleConfirm"
    @handleCancel="handleCancel"
  />
</template>

<script setup>
import {
  computed,
  ref,
  watch,
  nextTick,
  onMounted,
  onUnmounted,
  defineProps,
} from 'vue';
import { useStore } from 'vuex';
import ClipButton from '@/components/buttons/ClipButton.vue';
import ModalDelete from './modal-delete.vue';
import { Image } from 'ant-design-vue';
import { useI18n } from 'vue-i18n';
import VueTypes from 'vue-types';
import { TAB_WALKIE } from '@/util/common-constant.js';
import { formatTimeAudio } from '@/util/common-utils';
import { convertUnixToDate } from '@/util/common-utils';
import TextMess from './type-messages/text-mess.vue';
import ImageMess from './type-messages/image-mess.vue';
import VideoMess from './type-messages/video-mess.vue';
import AudioMess from './type-messages/audio-mess.vue';
import { useRoute } from 'vue-router';
const { state, dispatch } = useStore();
const scrollContainer = ref(null);
const isPlayingId = ref('');
const isHovered = ref(null);
const audioPlayer = ref(null);
const itemDelete = ref(null);
const locale = ref(localStorage.getItem('locale'));
const audioPlayers = ref({});
const currentPlayingMess = ref(null);
const currentCountdownInterval = ref(null);
const { t } = useI18n();
const listHistoryMess = computed(() => state.coordinator.historyMessages);
const itemValue = computed(() => state.coordinator.itemValue || '');
const userInfo = computed(() => state.auth.userInfo);
const isShowDelete = computed(() => state.coordinator.isShowDeleted);
const isLoadingMess = computed(() => state.coordinator.isLoadingMess);
const threshold = 30 * 60;
const listTime = ref([]);
const route = useRoute();
const reversedMessages = computed(() => {
  return listHistoryMess.value && listHistoryMess.value.messages
    ? [...listHistoryMess.value.messages].reverse()
    : [];
});
const props = defineProps({
  activeKey: VueTypes.string.def(''),
});
const scrollToBottom = () => {
  var container = document.querySelector('.custom-scroll');
  if (container) {
    var scrollHeight = container.scrollHeight;
    container.scrollTop = scrollHeight;
  }
};
onMounted(() => {
  nextTick(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 200);
  });
});

watch(reversedMessages, () => {
  handleTimeMess(reversedMessages.value);
  nextTick(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 200);
  });
});
const handleTimeMess = (data) => {
  const result = data.map((message, index) => {
    if (index === 0) {
      return { timeUnix: message.timeUnix, active: true };
    }

    const previousMessage = data[index - 1];
    const isActive = message.timeUnix - previousMessage.timeUnix >= threshold;

    return { timeUnix: message.timeUnix, active: isActive };
  });
  listTime.value = result;
};
const idUser = computed(() => state.auth.idUser);

function resetPreviousAudio() {
  if (currentPlayingMess.value) {
    const previousCountdownDisplay = document.getElementById(`countdownchat${currentPlayingMess.value.messageIdStr}`);
    if (previousCountdownDisplay) {
      previousCountdownDisplay.textContent = formatTimeCountDown(currentPlayingMess.value.length);
    }

    if (audioPlayer.value) {
      audioPlayer.value.pause();
    }

    if (currentCountdownInterval.value) {
      clearInterval(currentCountdownInterval.value);
    }
  }
}

function startCountdown(durationInSeconds, mess) {
  const countdownDisplay = document.getElementById(`countdownchat${mess.messageIdStr}`);
  const initialCountdownDisplayContent = countdownDisplay.textContent;
  countdownDisplay.textContent = formatTimeCountDown(durationInSeconds);

  currentCountdownInterval.value = setInterval(() => {
    durationInSeconds--;
    countdownDisplay.textContent = formatTimeCountDown(durationInSeconds);
    if (durationInSeconds <= 0) {
      clearInterval(currentCountdownInterval.value);
      isPlayingId.value = '';
      handlePause(mess);
      countdownDisplay.textContent = initialCountdownDisplayContent;
    }
  }, 1000);
}
const handlePlay = async (mess) => {
  resetPreviousAudio();
  await nextTick();
  const audio = new Audio(mess.url);
  audioPlayer.value = audio;
  audio.currentTime = 0;
  audio.play();
  startCountdown(mess.length, mess);
  currentPlayingMess.value = mess;
  isPlayingId.value = mess.messageIdStr;
};
const handlePause = async (mess) => {
  if (audioPlayer.value) {
    audioPlayer.value.pause();
  }
  if (currentCountdownInterval.value) {
    clearInterval(currentCountdownInterval.value);
  }
  currentPlayingMess.value = null;
  isPlayingId.value = '';
};

function formatTimeCountDown(seconds) {
  const minutes = Math.floor(seconds / 60)
    .toString()
    .padStart(2, '0');
  const remainingSeconds = (seconds % 60).toString().padStart(2, '0');
  return `${minutes}:${remainingSeconds}`;
}

const handleMouseEnter = (event, indexMess) => {
  isHovered.value = indexMess;
};
const handleMouseLeave = (event, indexMess) => {
  isHovered.value = null;
};
const handleDeleteMess = (item) => {
  let payload = {
    active: true,
    type: 'single',
  };
  dispatch('coordinator/isShowDelete', payload);
  itemDelete.value = item;
};
const handleCancel = () => {
  let payload = {
    active: false,
    type: 'single',
  };
  dispatch('coordinator/isShowDelete', payload);
};

const handleSendMess = (item) => {
  let value;
  if (item.senderId === userInfo.value.id) {
    value = {
      ...item,
      name: userInfo.value.fullName,
    };
  } else {
    value = {
      ...item,
      name: itemValue.value.otherInfo.peerName,
    };
  }
  dispatch('coordinator/selectMess', value);
  dispatch('coordinator/viewMember', TAB_WALKIE.SEND_MESSAGE);
};
const handleConfirm = () => {
  if (isShowDelete.value.type === 'single') {
    let payload = {
      dialogId: itemValue.value.dialogId,
      dialogType: itemDelete.value.dialogType,
      messageIdStr: itemDelete.value.messageIdStr,
    };
    dispatch('coordinator/deleteSingleMess', payload).then(() => {
      let payloads = {
        active: false,
        type: 'single',
      };
      dispatch('coordinator/isShowDelete', payloads);
    });
  } else {
    let payload = {
      dialogId: itemValue.value.dialogId,
      dialogType: itemValue.value.dialogType,
    };
    dispatch('coordinator/deleteAllMess', payload).then(() => {
      let payloads = {
        active: false,
        type: 'single',
      };
      dispatch('coordinator/isShowDelete', payloads);
    });
  }
};
const getFullNameAttached = (mess) => {
  let name;
  listHistoryMess.value.AttachedUser.map((item) => {
    if (item.Id === mess.senderId) {
      name = item.FullName;
    }
  });
  return name;
};
const getAvatarAttached = (mess) => {
  let avatar;
  listHistoryMess.value.AttachedUser.map((item) => {
    if (item.Id === mess.senderId) {
      avatar = item.Avatar;
    }
  });
  return avatar;
};
</script>

<style>

</style>
